/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
  background-color: #F6F6F6;
  -ms-overflow-style: none;
  height: 100vh;
  overflow: hidden;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  body {
    zoom: 75%;
  }

  body.no-zoom {
    zoom: unset;
  }

  #colorPopover-colorCode .MuiPopover-paper {
    zoom: 133%;
    top: 300px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1365px) {
  body.no-zoom {
    zoom: unset;
  }

  body.responsive-zoom {
    zoom: 80%;
  }

  #colorPopover-colorCode .MuiPopover-paper {
    zoom: 125%;
    top: 320px !important;
  }
}

body::-webkit-scrollbar {
  display: none;
}

.sigCanvas {
  width: 100% !important;
  height: 100% !important;
}

/* #root {
  height: 100vh;
  overflow-y: scroll;
} */
